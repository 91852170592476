.App {
	text-align: center;
	height: 100vh;
}

::-webkit-input-placeholder {
	color: white;
}

.react-virtualized-menu-placeholder {
	margin-top: 12px;
	color: #9a9a9a;
	text-align: center;
}
.react-virtualized-list-wrapper li {
	list-style: none;
}

a {
	color: rgb(14, 172, 0);
}

a:visited {
	color: rgb(14, 172, 0);
}

.node-previous {
	border: purple solid 2px !important;
}
